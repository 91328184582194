import React from "react";
import "./CatProgressBar.css";
import ProgressBar from "react-bootstrap/ProgressBar";

export default function CatProgressBar({ progress: wpm }) {
  
  return (
        <div className="CatProgressBar">
          <ProgressBar
            variant={"warning"}
            animated
            now={wpm}
          />
        </div>
  );
}
